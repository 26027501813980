<template>
  <nav class="bottom-0 grid grid-cols-3 h-12 fixed w-full z-50">
    <slot></slot>
  </nav>
</template>

<style lang="postcss" scoped>
nav{
  /* @apply bg-fable text-sable; */
  @apply bg-sable text-fable;
}

@media all and (display-mode: standalone) {
  nav{
    padding-bottom: 35px;
  }
}
</style>